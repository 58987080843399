export const companies = [
    { value: 'tpsa', viewValue: 'Oeschle' },
    { value: 'hpsa', viewValue: 'Promart' },
    { value: 'spsa', viewValue: 'Plaza Vea' },
    { value: 'rp', viewValue: 'Real Plaza' },
    { value: 'hesa', viewValue: 'Promart Ecuador' },
];

export const systems = [
    { value: 'attentions', viewValue: 'Atención de pedidos' },
    { value: 'security', viewValue: 'Validación de QR - Seguridad' },
    { value: 'withdraws', viewValue: 'Retiro en tienda - Cliente' },
    { value: 'post-sales', viewValue: 'Servicios Post Venta' },
    { value: 'queue-manager', viewValue: 'Gestor de colas' }
];

export const withdrawalValidationEnabled: any[] = [ 'hpsa', 'tpsa', 'spsa' ];

export const domainsAllowedInRegister: any[] = [
    '@promart.pe',
    '@promart.ec',
    '@spsa.pe',
    '@oechsle.pe',
    '@realplaza.com.pe',
    '@irdigital.pe',
    '@indigitalxp.pe',
    '@plazavea.pe',
    '@oslo.pe',
    '@tiendasmass.pe'
];

export const imagesWithdrawalDetail: any[] =  [
    {id: 0, name: 'imagensustento1', description: '' },
    {id: 1, name: 'imagensustento2', description: '' },
    {id: 2, name: 'imagensustento3', description: '' },
    {id: 3, name: 'imagensustento4', description: '' },
    {id: 4, name: 'imagensustento5', description: '' },
    {id: 5, name: 'imagensustento6', description: '' }
];

export const sale = [
    { value: 'Venta por Internet', viewValue: 'Ecommerce' },
    { value: 'Venta Empresa', viewValue: 'Venta empresa' },
    { value: 'Venta Directa', viewValue: 'Venta retail' }
];

export const categories = [
    { value: 'FOOD', viewValue: 'Food' },
    { value: 'NONFOOD', viewValue: 'Non Food' }
];

export enum Service {
    RETURN = 1,
    TECHNICAL_SERVICE,
    OTHERS,
    WITHDRAW,
    WITHDRAW_CAR
}

export const versionApp = 'v1.0.0';

export const commons = {
    companies,
    systems,
    service: Service,
    domainsAllowedInRegister,
    versionApp,
    imagesWithdrawalDetail,
    withdrawalValidationEnabled,
    sale,
    categories
}

